.custombutton li button {
  height: 20px;
}

.customsvg button svg {
  height: 20px;
  width: 20px;
}

.w-md-editor-content {
  position: relative;
  border-radius: 0 0 3px 0;
  height: calc(100% - 41.98px);
}