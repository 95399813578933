body {
  margin: 0;
  font-family: "Portrait Text Regular", serif, Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

div,
table,
td,
th,
input,
textarea,
select,
button {
  background-color: inherit;
}
